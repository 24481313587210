import { Component, OnInit, ElementRef, HostListener, OnDestroy, Renderer2 } from '@angular/core';
import { library } from '@fortawesome/fontawesome';
import faEye from '@fortawesome/fontawesome-pro-light';
import faEyeSlash from '@fortawesome/fontawesome-pro-light';

@Component({
  selector: 'app-parallax-firewatch',
  templateUrl: './parallax-firewatch.component.html',
  styleUrls: ['./parallax-firewatch.component.scss']
})
export class ParallaxFirewatchComponent implements OnInit, OnDestroy {

  invisible: any;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
    library.add(faEye, faEyeSlash);
    this.renderer.addClass(document.body, 'fullscreenmain');

    // if (window.DeviceOrientationEvent) {
    //   // window.addEventListener('deviceorientation', deviceOrientationHandler, false);
    //   // document.getElementById("doeSupported").innerText = "Supported!";
    //   this.renderer.addClass(document.body, 'deviceorientation');
    // }
    // else {
    //   this.renderer.addClass(document.body, 'NO-DEVICEORIENTATION');
    // }
  }

  ngOnInit() {
    if (matchMedia('(hover)').matches) {
      this.elRef.nativeElement.addEventListener('mousemove', this.onMouseMove);
    }
    // else if (window.DeviceMotionEvent) {
    // else if (window.DeviceOrientationEvent) {
    //   console.log('should support device tilting');
    //   // window.addEventListener('devicemotion', this.onDeviceMove);
    //   // window.addEventListener('deviceorientation', this.onDeviceMove);
    //   alert('Please visit this page on a device that supports hover/mousemouse\n\nMobile tilting will be supported soon');
    // }
    else {
      console.log('device has no mouse hover support and no tilt orientation detection');
      alert('Please visit this page on a device that supports hover/mousemove');
    }
  }

  ngOnDestroy() {
    this.elRef.nativeElement.removeEventListener('mousemove', this.onMouseMove);
    this.renderer.removeClass(document.body, 'fullscreenmain');
  }

  // @HostListener('mousemove', ['$event'])
  onMouseMove(e) {
    // console.log(e);

    if ( (e.target.classList.contains('descripttoggle')) || (e.target.classList.contains('.main-content')) || (e.target.classList.contains('.intro')) ) {
      return;
    }
    // if ( (e.target.classList.contains('collectionitem--mouser')) || (e.target.classList.contains('collectionitem'))) {
      e.preventDefault();
      e.stopPropagation();

      var currentX = e.offsetX;
      var currentY = e.offsetY;

      var heightOfContainer = e.target.offsetHeight;
      var widthOfContainer = e.target.offsetWidth;

      var unmappedFinalX = currentX/widthOfContainer; // 0 to 1 -- needs to change to -1 to +1
      var unmappedFinalY = currentY/heightOfContainer;

      // console.log("currentX = " + currentX, "currentY = " + currentY, "width = " + widthOfContainer, "height = " + heightOfContainer);

      // console.log((num - 0) * (out_max - out_min) / (1 - 0) + out_min);
      // map answers from 0 to 1, to -1 to +1
      // (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
      var mappedFinalX = (unmappedFinalX - 0) * (1 - -1) / (1 - 0) + -1;
      var mappedFinalY = (unmappedFinalY - 0) * (1 - -1) / (1 - 0) + -1;

      if (e.target.classList.contains('collectionitem') ) {
        e.target.style.setProperty('--x', mappedFinalX);
        e.target.style.setProperty('--y', mappedFinalY);
      }
      else {
        e.target.parentNode.style.setProperty('--x', mappedFinalX);
        e.target.parentNode.style.setProperty('--y', mappedFinalY);
      }
    }

  onDeviceMove(e) {
    // console.log('device moving');
    // console.log(e);
    // for (i = 0; i < 2; i++) {
    //   console.log(e);
    // }
    var currentX = e.beta;
    var currentY = e.gamma;

    // if (currentX >  90) { currentX =  90};
    // if (currentX < -90) { currentX = -90};
    // console.log(currentX, currentY);

    // currentX += 90;
    // currentY += 90;

    var cntr = document.querySelector('.imgcntr')
    // var heightOfContainer = cntr.offsetHeight;
    // var widthOfContainer = cntr.offsetWidth;

    // var unmappedFinalX = currentX / widthOfContainer;
    // var unmappedFinalY = currentY / heightOfContainer;
    //
    // var mappedFinalX = (unmappedFinalX - 0) * (1 - -1) / (1 - 0) + -1;
    // var mappedFinalY = (unmappedFinalY - 0) * (1 - -1) / (1 - 0) + -1;

    // console.log(mappedFinalX, mappedFinalY);

    // if (e.target.classList.contains('collectionitem') ) {
    //   e.target.style.setProperty('--x', mappedFinalX);
    //   e.target.style.setProperty('--y', mappedFinalY);
    // }
    // else {
      // cntr.style.setProperty('--x', mappedFinalX);
      // cntr.style.setProperty('--y', mappedFinalY);

      // document.querySelector('.imgcntr img').style.setProperty('--x', mappedFinalX);
      // document.querySelector('.imgcntr img').style.setProperty('--y', mappedFinalY);
    // }
  }
  // }
  // or use mobile device tilt and orientation to do the same thing as hover -- see
  // https://developers.google.com/web/fundamentals/native-hardware/device-orientation/

}
