import { Component, OnInit, HostListener } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { DataService } from '../../data.service';
import { ModeService } from 'app/mode.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']//,
  // encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  links: Object[];

  getNavigationItems() {
    this.links = [
      {
        title: 'About',
        link: '/'
      },
      {
        title: 'Work',
        link: 'work'
      },
      {
        title: 'Writing',
        link: 'writing'
      },
      // {
      //   title: 'Captures',
      //   link: 'captures'
      // },
      {
        title: 'Creations',
        link: 'creations'
      }
    ]
  }

  constructor(
    public data: DataService,
    public mode: ModeService
  ) {
    this.getNavigationItems();
  }

  ngOnInit() {
  }

  toggleMenu() {
    var header = document.querySelector('header');
    header.classList.toggle('menu__active');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    // console.log(e);
    var header = document.querySelector('header');
    var main = document.querySelector('main');

    var bodyRect = document.body.getBoundingClientRect();
    var mainRect = main.getBoundingClientRect();

    var mainOffsetY = (mainRect.top - bodyRect.top);
    // var target = e.target.scrollingElement.clientHeight/3.3;
    // if (e.target.scrollingElement.scrollTop < target) {
    // console.log(mainRect);
    // console.log(mainOffsetY);
    if (mainRect.top > 100) {
      header.classList.remove('header__scrolled');
    }
    else {
      header.classList.add('header__scrolled');
    }
  }

}
