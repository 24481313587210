import { Component, OnInit } from '@angular/core';
import fontawesome from '@fortawesome/fontawesome';
import solid from '@fortawesome/fontawesome-pro-solid'; // or load only the moon and sun instead of the whole thing?

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public currentYear: number = new Date().getFullYear();

  constructor() {
    fontawesome.library.add(solid);
  }

  changeTheme() {
    console.log('change theme');
    // if (document.body.classList.contains('alternate-ui')) {
    //   document.body.classList.remove('alternate-ui');
    // }
    // else {
    //   document.body.classList.add('alternate-ui');
    // }
    document.body.classList.toggle('alternate-ui');

  }

  ngOnInit() {
  }

}
