import { Component, OnInit } from '@angular/core';
import { ModeService } from 'app/mode.service';

@Component({
  selector: 'app-belvis',
  templateUrl: './belvis.component.html'
  // styleUrls: ['./belvis.component.scss', '../_work.scss']
})
export class BelvisComponent implements OnInit {

  constructor(
    public mode: ModeService
  ) { }

  ngOnInit() {
  }

}
