import { Collection } from './collection';

export const WRITINGS: Collection[] = [
  {
    name: 'Why I decided to go freelance',
    definition: 'going-freelance',
    link: 'writing/why-i-decided-to-go-freelance'
  },
  {
    name: 'When a client says the quote is too high',
    definition: 'quote-too-high',
    link: 'writing/when-a-client-says-the-quote-is-too-high'
  },
  {
    name: 'Designing and developing with accessibility in mind',
    definition: 'accessibility',
    link: 'writing/designing-and-developing-with-accessibility-in-mind'
  }
]
