import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// HTTP Capability
import { HttpClientModule } from '@angular/common/http';

// Routing
import { AppRoutingModule } from './app-routing.module';

// Services
import { DataService } from './data.service';
import { ModeService } from './mode.service';
import { WordpressService } from './wordpress.service';

// Site Components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FooterComponent } from './components/footer/footer.component';
import { SocialLinksComponent } from './components/social-links/social-links.component';

import { BackgroundTextComponent } from './components/background-text/background-text.component';
import { BackgroundItemComponent } from './components/background-item/background-item.component';

// Page Components
import { AboutComponent } from './components/about/about.component';

import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { PortfolioItemComponent } from './components/portfolio-item/portfolio-item.component';

import { WritingComponent } from './components/writing/writing.component';
import { WritingItemComponent } from './components/writing-item/writing-item.component';

import { ExperimentsComponent } from './components/experiments/experiments.component';
import { CollectionItemComponent } from './components/collection-item/collection-item.component';

// Portfolio Items
import { TracyLoiComponent } from './components/work/tracy-loi/tracy-loi.component';
import { ZielAbiComponent } from './components/work/ziel-abi/ziel-abi.component';
import { DownSyndromeAwarenessComponent } from './components/work/down-syndrome-awareness/down-syndrome-awareness.component';
import { ThreeDgComponent } from './components/work/three-dg/three-dg.component';
import { IronDeficiencyComponent } from './components/work/iron-deficiency/iron-deficiency.component';
import { BlocklogixComponent } from './components/work/blocklogix/blocklogix.component';
import { BelvisComponent } from './components/work/belvis/belvis.component';

// Writing Items
import { GoingFreelanceComponent } from './components/writings/going-freelance/going-freelance.component';
import { StaticItemFooterComponent } from './components/static-item-footer/static-item-footer.component';
import { QuoteTooHighComponent } from './components/writings/quote-too-high/quote-too-high.component';
import { AccessibilityComponent } from './components/writings/accessibility/accessibility.component';

// Creations
import { ParallaxFirewatchComponent } from './components/creations/parallax-firewatch/parallax-firewatch.component';
import { WritingWPComponent } from './components/writings/writing-wp/writing-wp.component';
import { SafePipe } from './pipes/safe.pipe';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SocialLinksComponent,
    ThreeDgComponent,
    NavigationComponent,
    AboutComponent,
    PortfolioComponent,
    TracyLoiComponent,
    ZielAbiComponent,
    DownSyndromeAwarenessComponent,
    PortfolioItemComponent,
    BackgroundTextComponent,
    BackgroundItemComponent,
    IronDeficiencyComponent,
    BlocklogixComponent,
    BelvisComponent,
    WritingComponent,
    GoingFreelanceComponent,
    WritingItemComponent,
    StaticItemFooterComponent,
    QuoteTooHighComponent,
    AccessibilityComponent,
    ParallaxFirewatchComponent,
    ExperimentsComponent,
    CollectionItemComponent,
    WritingWPComponent,
    SafePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [
    DataService,
    ModeService,
    WordpressService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
