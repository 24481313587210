import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationExtras } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WordpressService } from '../../../wordpress.service';

// import { Title, Meta } from '@angular/platform-browser';

import { DataService } from '../../../data.service';

@Component({
  selector: 'app-writing-wp',
  templateUrl: './writing-wp.component.html',
  styleUrls: ['./writing-wp.component.scss']
})
export class WritingWPComponent implements OnInit {

  post$: Observable<any[]>;
  postTitle: string;
  postHeaderBackground: string;
  postExcerpt: string;
  postTags: Array<string>;
  postDefinition: string;
  postContent: string;
  postImageAsset: string;

  // @ViewChild('container') container: ElementRef;
  // id: number;
  // @Input('data-posttitle') posttitle: string;
  // private sub: any;

  constructor(
    // private router: Router,
    private route: ActivatedRoute,
    private wp: WordpressService,
    // private titleService: Title,
    // private metaService: Meta
    private data: DataService
  ) {
    // this.router.events.subscribe( (event: RouterEvent) => console.log(event));
    // if (event.)
    // this.sub = this.route.params.subscribe(params => {
    //    this.id = +params['slug'];
    //  }
    // console.log(this.id);
    const postSlug = this.route.snapshot.paramMap.get('slug');
    // console.log(postSlug);
    // console.log('here');
    this.post$ = this.wp.getPostDetail(postSlug);

    // this.route.snapshot.data.title = postSlug;

    // this.post$.subscribe(response => {
    //   this.posts = response.data
    //   console.log(this.posts);
    // )};
    this.post$.subscribe((response: any) => {
      // this.post$ = response.data;
      // console.log(response);
      // console.log(response.title);
      this.postTitle = response.title;
      this.postHeaderBackground = response.headerbackground;
      this.postExcerpt = response.excerpt;
      this.postTags = response.tags;

      this.postContent = response.content;

      // this.postDefinition = response.definition;

      this.postImageAsset = response.imageasset;

      this.data.setMetas('/' + postSlug, this.postTitle, this.postExcerpt, this.postImageAsset);
    });
    // this.route.snapshot.data.title = postSlug;
    // this.post$.subscribe(posts => this.posts = posts);

    // this.route.snapshot.data.title = this.postTitle;

    // console.log(container.nativeElement.getAttribute('title'));

    // console.log(this.posttitle);

    // console.log(this.post$);
    // for (var def of this.post$) { // def is of the array item type no casting necessary
    //   var test = <GridColumnDefinition>this.title.rendered;
    //   console.log(test);
    // }
    // for (let test of this.post$) {
    //   console.log(test);
    // }
    // for (let test in this.post$) {
    //   console.log(test);
    // }

    // this.posts.forEach(post =>
    //   console.log(post);
    // );

    // this.title = postSlug;
    // this.description = postSlug;
    // console.log(this.title);
    // console.log(this.description);
    // console.log('39');
    // this.sub = this.route
    //   .data
    //   .subscribe(v => console.log(postSlug));
    // // this.router.getCurrentNavigation().extras.state;
    // this.route.queryParams.subscribe(params => {
    //         this.title = 'params["firstname"]';
    //         // this.lastname = params["lastname"];
    //     });

    // console.log(this.route.snapshot.data);
    // this.route.snapshot.data.title = postSlug;
    // this.route.snapshot.data.imageasset = 'something.png';
  }

  ngOnInit() {
    // this.title = postSlug;
  }

  // public setMetas( newTitle: string) {
  //   this.titleService.setTitle( newTitle );
  // }

}
