import { Component, OnInit } from '@angular/core';
import { Collection } from '../../collection';
import { WRITINGS } from '../../writing-items';

import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WordpressService } from '../../wordpress.service';

import { DataService } from '../../data.service';

@Component({
  selector: 'app-writing',
  templateUrl: './writing.component.html',
  styleUrls: ['./writing.component.scss']
})

export class WritingComponent implements OnInit {

  writings: Collection[];
  element: HTMLElement;

  posts$: Observable<any[]>;

  constructor(
    private wp: WordpressService,
    public data: DataService
  ) {
    this.writings = WRITINGS;
    this.posts$ = this.wp.getPosts();
  }

  ngOnInit() {
  }

}
