import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Collection } from '../../collection';
import { DataService } from '../../data.service';
import { ViewEncapsulation } from '@angular/core';
import { ModeService } from 'app/mode.service';

@Component({
  selector: 'app-portfolio-item',
  templateUrl: './portfolio-item.component.html',
  encapsulation: ViewEncapsulation.None
})
export class PortfolioItemComponent implements OnInit {

  @Input() portfolio: Collection;
  @Input() isAlternate: boolean;

  constructor(
    public data: DataService,
    public mode: ModeService
  ) { }

  // @HostListener('mousemove', ['$event'])
  // onMouseMove(e) {
  //   // console.log(e);
  //   if ( (e.target.classList.contains('collectionitem--mouser')) || (e.target.classList.contains('collectionitem'))) {
  //     e.preventDefault();
  //     e.stopPropagation();
  //
  //     var currentX = e.offsetX;
  //     var currentY = e.offsetY;
  //
  //     var heightOfContainer = e.target.offsetHeight;
  //     var widthOfContainer = e.target.offsetWidth;
  //
  //     var unmappedFinalX = currentX/widthOfContainer; // 0 to 1 -- needs to change to -1 to +1
  //     var unmappedFinalY = currentY/heightOfContainer;
  //
  //     // console.log("currentX = " + currentX, "currentY = " + currentY, "width = " + widthOfContainer, "height = " + heightOfContainer);
  //
  //     // console.log((num - 0) * (out_max - out_min) / (1 - 0) + out_min);
  //     // map answers from 0 to 1, to -1 to +1
  //     // (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  //     var mappedFinalX = (unmappedFinalX - 0) * (1 - -1) / (1 - 0) + -1;
  //     var mappedFinalY = (unmappedFinalY - 0) * (1 - -1) / (1 - 0) + -1;
  //
  //     if (e.target.classList.contains('collectionitem') ) {
  //       e.target.style.setProperty('--x', mappedFinalX);
  //       e.target.style.setProperty('--y', mappedFinalY);
  //     }
  //     else {
  //       e.target.parentNode.style.setProperty('--x', mappedFinalX);
  //       e.target.parentNode.style.setProperty('--y', mappedFinalY);
  //     }
  //   }
  // }
  // or use mobile device tilt and orientation to do the same thing as hover -- see
  // https://developers.google.com/web/fundamentals/native-hardware/device-orientation/

  ngOnInit() {
  }

}
