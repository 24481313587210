import { Component, OnInit } from '@angular/core';
// import fontawesome from '@fortawesome/fontawesome';
// import faBrands from '@fortawesome/fontawesome-free-brands';
import { library } from '@fortawesome/fontawesome';
import faInstagram from '@fortawesome/fontawesome-free-brands';
import faTwitter from '@fortawesome/fontawesome-free-brands';
import faLinkedin from '@fortawesome/fontawesome-free-brands';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class SocialLinksComponent implements OnInit {

  icons: Object[];

  constructor() {
    // fontawesome.library.add(faBrands);
    library.add(faInstagram, faTwitter, faLinkedin);

    this.icons = [
      {
        icon: 'fa-dribbble',
        link: 'https://dribbble.com/dhruveonmars',
        title: 'Dribbble'
      },
      {
        icon: 'fa-instagram',
        link: 'https://instagram.com/dhruveonmars',
        title: 'Instagram'
      },
      // {
      //   icon: 'fa-medium-m',
      //   link: 'http://medium.com/@DhruveOnMars',
      //   title: 'Medium'
      // },
      {
        icon: 'fa-twitter',
        link: 'https://twitter.com/@dhruveonmars',
        title: 'Twitter'
      },
      {
        icon: 'fa-linkedin',
        link: 'https://linkedin.com/in/dhruveonmars',
        title: 'LinkedIn'
      }
    ];
  }

  ngOnInit() {
  }

}
