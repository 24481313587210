import { Component, OnInit, Input } from '@angular/core';
import { Collection } from '../../collection';
import { DataService } from '../../data.service';
import { ModeService } from 'app/mode.service';

@Component({
  selector: 'app-collection-item',
  templateUrl: './collection-item.component.html',
  styleUrls: ['./collection-item.component.scss']
})
export class CollectionItemComponent implements OnInit {

  @Input() collection: Collection;
  @Input() isAlternate: boolean;

  constructor(
    public data: DataService,
    public mode: ModeService
  ) { }

  ngOnInit() {
  }

}
