import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {

  url: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.url = 'https://writing.dhruveonmars.com/wp-json/dom/v1';
  }

  getPosts(): Observable<any[]> {
    // TODO: stuff for when fails to get content, etc.
    return this.httpClient.get<any[]>(this.url + '/get-post-covers', {
      // params: {
      //   per_page: '6'
      // }
    });
  }

  getPostDetail(slug): Observable<any[]> {
    // return this.httpClient.get<any[]>(this.url + '/get-post-details/' + slug, { // with no params - could work with the rest route being /get-post-details/slug ??
    return this.httpClient.get<any[]>(this.url + '/get-post-details', {
      params: {
        slug: slug
      }
    }).pipe(
      retry(1), catchError(error => {
        return throwError(error.message);
      })
    );
    // .catch((err: HttpErrorResponse) => {
    //   console.log('an error occured', err.error);
    // })
  }

  // getPostDetail(slug): Observable<any[]> {
  //   this.httpClient.get(this.url + '/wp-json/wp/v2/posts?slug=test-post-1').subscribe((response:any) => {
  //     console.log(response.data);
  //   })
  // }
}
