import { Component, OnInit } from '@angular/core';
import { Collection } from '../../collection';
import { CREATIONS } from '../../creations';

@Component({
  selector: 'app-experiments',
  templateUrl: './experiments.component.html',
  styleUrls: ['./experiments.component.scss']
})
export class ExperimentsComponent implements OnInit {

  creations: Collection[];
  element: HTMLElement;

  constructor() {
    this.creations = CREATIONS;
  }

  ngOnInit() {
  }

}
