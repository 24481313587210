import { Component, OnInit } from '@angular/core';
import { DataService } from '../../data.service';
import { ViewEncapsulation } from '@angular/core';
import { ModeService } from '../../mode.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  links: Object[];

  getNavigationItems() {
    this.links = [
      {
        title: 'About',
        link: '/'
      },
      {
        title: 'Work',
        link: 'work'
      },
      {
        title: 'Writing',
        link: 'writing'
      },
      // {
      //   title: 'Captures',
      //   link: 'captures'
      // },
      {
        title: 'Creations',
        link: 'creations'
      }
    ]
  }

  constructor(
    public data: DataService,
    public mode: ModeService
  ) {
    this.getNavigationItems();
  }

  ngOnInit() {
  }

}
