import { Component, OnInit, Input, HostListener, NgModule } from '@angular/core';

@Component({
  selector: 'app-background-text',
  templateUrl: './background-text.component.html',
  styleUrls: ['./background-text.component.scss']
})
export class BackgroundTextComponent implements OnInit {

  @Input() text: string;
  @Input() speed: number;

  @Input() side: string;

  constructor() {
  }

  ngOnInit() {

  }

}
