import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModeService } from 'app/mode.service';

@Component({
  selector: 'app-ziel-abi',
  templateUrl: './ziel-abi.component.html'
})
export class ZielAbiComponent implements OnInit {

  constructor(
    private router: Router,
    private titleService: Title,
    public mode: ModeService
  ) { }

  ngOnInit() {
  }

}
