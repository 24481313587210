import {mergeMap, map, filter} from 'rxjs/operators';
import { Component, OnInit, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { DataService } from './data.service';

import * as AOS from 'aos';
// import * as MyFontsKit from 'myFontsKit';

declare function onScroll(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // title = 'Dhruve Shah';

  title: string;
  // description: string;
  url: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    public data: DataService
  ) {
    this.title = 'Dhruve on Mars';
    // this.description = 'Don\'t stop creating. Whatever your passion is, enjoy the process and the beauty behind it. Be Wild. Be Crazy. Be Reckless.';
    this.url = 'https://dhruveonmars.com';
  }

  ngOnInit() {

    //uncomment and change this to false if you're having trouble with WOFFs
    // let woffEnabled = true;
    //to place your webfonts in a custom directory
    //uncomment this and set it to where your webfonts are.
    // let customPath = "/assets/fonts";
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),)
      .subscribe((event) => {
        this.data.setMetas(this.router.url, event['title'], event['description'], event['imageasset']);
    });


    AOS.init({
      duration: 1200,
      delay: 200,
      once: true
    });

    window.onscroll = function(e) {
      onScroll();
    }
    window.onresize = function(e) {
      onScroll();
    }

  }

}
