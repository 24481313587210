import { Component, NgZone, HostListener, OnInit } from '@angular/core';
import { Collection } from '../../collection';
import { PORTFOLIOS } from '../../portfolio-items';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html'
})
export class PortfolioComponent implements OnInit {

  portfolios: Collection[];
  element: HTMLElement;

  constructor() {
    this.portfolios = PORTFOLIOS;
  }

  ngOnInit() {
  }

}
