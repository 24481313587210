import { Collection } from './collection';

export const PORTFOLIOS: Collection[] = [
  {
    name: 'ZielABI',
    definition: 'zielabi',
    link: 'work/zielabi'
  },
  {
    name: 'Down Syndrome Awareness',
    definition: 'downsyndrome',
    link: 'work/downsyndrome'
  },
  {
    name: '3DG Design Services',
    definition: '3dg',
    link: 'work/3dg'
  },
  {
    name: 'BlockLogix',
    definition: 'blocklogix',
    link: 'work/blocklogix'
  },
  {
    name: 'Iron Deficiency',
    definition: 'irondeficiency',
    link: 'work/irondeficiency'
  },
  {
    name: 'Tracy Loi',
    definition: 'tracyloi',
    link: 'work/tracyloi'
  },
  {
    name: 'Belvis De Las Navas',
    definition: 'belvis',
    link: 'work/belvis'
  }
]
