import { Component, OnInit } from '@angular/core';
import { ModeService } from 'app/mode.service';

@Component({
  selector: 'app-blocklogix',
  templateUrl: './blocklogix.component.html',
  styleUrls: ['./blocklogix.component.scss']
  // styleUrls: ['./blocklogix.component.scss', '../_work.scss']
})
export class BlocklogixComponent implements OnInit {

  constructor(
    public mode: ModeService
  ) { }

  ngOnInit() {
  }

}
