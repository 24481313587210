
import {timer,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ModeService {

  public darkMode: boolean = false;

  constructor() {
    const matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    if (matchMedia.matches) {
      this.changeTheme();
    }
  }

  changeTheme() {
    // do something here for fading images in and out
    // const anims = document.querySelectorAll('.main-content .aos-animate');
    // [].forEach.call(anims, function (anim) {
    //   anim.classList.remove('aos-animate');
    // });

    let scrollPos = window.scrollY || document.getElementsByTagName("html")[0].scrollTop;
    // console.log(scrollPos);

    var htmlCntr = document.querySelector('html');
    htmlCntr.classList.toggle('dark--mode');
    // htmlCntr.classList.add('themeswapping');
    this.darkMode = !this.darkMode;

    timer(100)
      .subscribe(i => {
        let newScrollPos = window.scrollY || document.getElementsByTagName('html')[0].scrollTop;
        // console.log(newScrollPos);
        if (newScrollPos != scrollPos) {
          // console.log('need to change scroll position');
          window.scrollTo(0, scrollPos);
        }
      });

    // Observable.timer(4500)
    //   .subscribe(i => {
    //     htmlCntr.classList.remove('themeswapping');
    //   });

  }

}
