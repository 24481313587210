
import {timer,  BehaviorSubject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Title, Meta } from '@angular/platform-browser';

import { environment } from './../environments/environment';

declare function onScroll(): any;

@Injectable()
export class DataService {

  title: string;
  // description: string;
  url: string;

  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.title = 'Dhruve on Mars';
    // this.description = 'Don\'t stop creating. Whatever your passion is, enjoy the process and the beauty behind it. Be Wild. Be Crazy. Be Reckless.';
    // this.url = 'https://dhruveonmars.com';
    this.url = environment.url;
    // console.log(environment.url);
  }

  routerLinkClicked(link, e) {
    e.preventDefault();
    if ( ('/' + link === this.router.url) || (link === this.router.url) ) {
      // window.scrollTo(0, 0);
      // TODO: animate this!!!
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
      return;
    }
    // if (isPost) {
    //   console.log('okkkkkk,,, here we go...,');
    // }
    const fadeOutItems = document.querySelectorAll('.main-content > *:not(router-outlet)');
    [].forEach.call(fadeOutItems, function (fadeOutItem) {
      fadeOutItem.classList.add('aos-fadeout');
    });
    document.body.style.overflow = 'hidden';
    timer(500)
      .subscribe(i => {
        window.scrollTo(0, 0);
      });

    timer(900)
      .subscribe(i => {
        this.router.navigate([link]);
        document.body.style.overflow = '';
      });
  }

  parallaxPositions() {
    onScroll();
  }


  setMetas(url: string, newTitle: string, newDescription: string, newImage: string) {
    // console.log('testing');

    // UPDATE TITLE
    // this.titleService.setTitle('testing');
    this.titleService.setTitle(url === '/' ? this.title + ' | ' + newTitle : newTitle + ' | ' + this.title);

    // UPDATE TITLE METATAGS
    this.metaService.updateTag({ name: 'title', content: url === '/' ? this.title + ' | ' + newTitle : newTitle + ' | ' + this.title });
    this.metaService.updateTag({ property: 'og:title', content: url === '/' ? this.title + ' | ' + newTitle : newTitle + ' | ' + this.title });
    this.metaService.updateTag({ property: 'twitter:title', content: url === '/' ? this.title + ' | ' + newTitle : newTitle + ' | ' + this.title });

    // UPDATE DESCRIPTIONS METATAGS
    this.metaService.updateTag({ name: 'description', content: newDescription })
    this.metaService.updateTag({ property: 'og:description', content: newDescription });
    this.metaService.updateTag({ property: 'twitter:description', content: newDescription });

    // UPDATE URL METATAGS
    this.metaService.updateTag({ property: 'og:url', content: url === '/' ? this.url : this.url + url });
    this.metaService.updateTag({ property: 'twitter:url', content: url === '/' ? this.url : this.url + url });

    // UPDATE IMAGE METATAGS
    this.metaService.updateTag({ property: 'og:image', content: newImage !== '' ? this.url + '/' + newImage : this.url + '/' + 'illustration.png'});
    this.metaService.updateTag({ property: 'twitter:image', content: newImage !== '' ? this.url + '/' + newImage : this.url + '/' + 'illustration.png'});
  }

}
