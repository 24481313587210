import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Import Routes (Pages Components)
import { AboutComponent } from './components/about/about.component';
import { PortfolioComponent } from './components/portfolio/portfolio.component';
import { WritingComponent } from './components/writing/writing.component';
import { ExperimentsComponent } from './components/experiments/experiments.component';

// Portfolio Components
import { TracyLoiComponent } from './components/work/tracy-loi/tracy-loi.component';
import { ThreeDgComponent } from './components/work/three-dg/three-dg.component' ;
import { ZielAbiComponent } from './components/work/ziel-abi/ziel-abi.component';
import { DownSyndromeAwarenessComponent } from './components/work/down-syndrome-awareness/down-syndrome-awareness.component';
import { IronDeficiencyComponent } from './components/work/iron-deficiency/iron-deficiency.component';
import { BlocklogixComponent } from './components/work/blocklogix/blocklogix.component';
import { BelvisComponent } from './components/work/belvis/belvis.component';

// Writing Components
import { GoingFreelanceComponent } from './components/writings/going-freelance/going-freelance.component';
import { QuoteTooHighComponent } from './components/writings/quote-too-high/quote-too-high.component';
import { AccessibilityComponent } from './components/writings/accessibility/accessibility.component';
import { WritingWPComponent } from './components/writings/writing-wp/writing-wp.component';

// Creation Components
import { ParallaxFirewatchComponent } from './components/creations/parallax-firewatch/parallax-firewatch.component';

const routes: Routes = [
  {
    path: '',
    component: AboutComponent,
    data: {
      title: 'Freelance Web & App Developer who likes to Design',
      description: 'Don\'t stop creating. Whatever your passion is, enjoy the process and the beauty behind it. Be Wild. Be Crazy. Be Reckless.',
      imageasset: ''
    }
  },
  {
    path: 'writing',
    children: [
      {
        path: '',
        component: WritingComponent,
        data: {
          title: 'Writing',
          description: 'Stuff I\'ve been writing',
          imageasset: '/assets/images/writing_cover.png'
        }
      },
      {
        path: 'why-i-decided-to-go-freelance',
        component: GoingFreelanceComponent,
        data: {
          title: 'Why I decided to go freelance',
          description: 'Why I decided to go freelance, instead of sticking with a 9 to 5 job',
          imageasset: 'assets/images/goingfreelance/featured.png'
        }
      },
      {
        path: 'when-a-client-says-the-quote-is-too-high',
        component: QuoteTooHighComponent,
        data: {
          title: 'When a client says the quote is too high',
          description: 'Things I\'ve learned, and do when a client says the quote is too high for them',
          imageasset: 'assets/images/quotetoohigh/featured.png'
        }
      },
      {
        path: 'designing-and-developing-with-accessibility-in-mind',
        component: AccessibilityComponent,
        data: {
          title: 'Designing and developing with accessibility in mind',
          description: 'Some ways to ensure that whatever you\'re designing or developing is accessible',
          imageasset: 'assets/images/accessibility/featured.png'
        }
      },
      {
        path: ':slug',
        component: WritingWPComponent,
        data: {
          title: 'Post through Wordpress',
          description: 'This is a temporary description, awaiting full description from Wordpress',
          imageasset: ''
        }
      }
    ]
  },
  {
    path: 'creations',
    children: [
      {
        path: '',
        component: ExperimentsComponent,
        data: {
          title: 'Creations',
          description: 'Stuff I\'ve been experimenting with and creating',
          imageasset: '/assets/images/creations_cover.png'
        }
      },
      {
        path: 'parallax-firewatch',
        component: ParallaxFirewatchComponent,
        data: {
          title: 'Parallax Firewatch',
          description: 'I had this idea when I first saw the Firewatch Website, of how it could work as a parallax wallpaper on the iPhone, if Apple ever allowed using multiple images as backgrounds.'
        }
      }//,
      // {
      //   path: ':slug',
      //   component: GoingFreelanceComponent,
      //   data: {
      //     title: 'something',
      //     description: 'Something else'
      //   }
      // }
    ]
  },
  {
    path: 'work',
    children: [
      {
        path: '',
        component: PortfolioComponent,
        data: {
          title: 'Selected Work',
          description: 'A sample of work I have been involved with',
          imageasset: '/assets/images/work_cover.png'
        }
      },
      {
        path: 'tracyloi',
        component: TracyLoiComponent,
        data: {
          title: 'Tracy Loi',
          description: 'A small and simple html/css portfolio website',
          imageasset: ''
        }
      },
      {
        path: '3dg',
        component: ThreeDgComponent,
        data: {
          title: '3DG',
          description: 'A Wordpress-based website to showcase the work and services that 3DG Design Services offers',
          imageasset: ''
        }
      },
      {
        path: 'zielabi',
        component: ZielAbiComponent,
        data: {
          title: 'ZielABI',
          description: 'An app built to help high school students in Germany track their Abiture, to help ensure they stay on track to get the grades they need',
          imageasset: 'assets/images/zielabi/featured@2000w.png'
        }
      },
      {
        path: 'downsyndrome',
        component: DownSyndromeAwarenessComponent,
        data: {
          title: 'Down Syndrome Awareness',
          description: 'A website built to raise awareness of down syndrome in Saudi Arabia',
          imageasset: ''
        }
      },
      {
        path: 'irondeficiency',
        component: IronDeficiencyComponent,
        data: {
          title: 'Iron Deficiency',
          description: 'The websites and apps were built to help people become more aware of iron deficiency, and to learn more about iron deficiency, it\'s causes, symptoms and treatment options',
          imageasset: ''
        }
      },
      {
        path: 'blocklogix',
        component: BlocklogixComponent,
        data: {
          title: 'BlockLogix',
          description: 'A Wordpress website for a startup that aimed to help people understand blockchain and bitcoin, and educate people how it could apply to their business',
          imageasset: ''
        }
      },
      {
        path: 'belvis',
        component: BelvisComponent,
        data: {
          title: 'Belvis',
          description: 'A one-page wordpress website built for a Spanish Oil Company, Belvis De Las Navas',
          imageasset: ''
        }
      }
    ]
  },
  // old paths redirect to new paths
  {
    path: 'tracyloi',
    redirectTo: 'work/tracyloi'
  },
  {
    path: '3dg',
    redirectTo: 'work/3dg'
  },
  {
    path: 'zielabi',
    redirectTo: 'work/zielabi'
  },
  {
    path: 'downsyndrome',
    redirectTo: 'work/downsyndrome'
  },
  {
    path: 'irondeficiency',
    redirectTo: 'work/irondeficiency'
  },
  {
    path: 'blocklogix',
    redirectTo: 'work/blocklogix'
  },
  {
    path: 'belvis',
    redirectTo: 'work/belvis'
  },
  {
    path: '**',
    redirectTo: ''
  },
]

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
