import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WordpressService } from '../../../wordpress.service';

@Component({
  selector: 'app-going-freelance',
  templateUrl: './going-freelance.component.html',
  styleUrls: ['./going-freelance.component.scss']
})
export class GoingFreelanceComponent implements OnInit {

  post$: Observable<any[]>;
  // id: number;
  // private sub: any;

  constructor(
    // private router: Router,
    private route: ActivatedRoute,
    private wp: WordpressService
  ) {
    // this.router.events.subscribe( (event: RouterEvent) => console.log(event));
    // if (event.)
    // this.sub = this.route.params.subscribe(params => {
    //    this.id = +params['slug'];
    //  }
    // console.log(this.id);
    const idr = this.route.snapshot.paramMap.get('slug');
    console.log(idr);
    this.post$ = this.wp.getPostDetail(idr);
    // console.log(this.wp.getPostDetail());
  }

  ngOnInit() {
  }

}
