import { Component, OnInit, Input } from '@angular/core';
import { Collection } from '../../collection';
import { DataService } from '../../data.service';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-writing-item',
  templateUrl: './writing-item.component.html',
  styleUrls: ['./writing-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WritingItemComponent implements OnInit {

  @Input() writing: Collection;
  @Input() isAlternate: boolean;

  constructor(
    public data: DataService
  ) {}

  ngOnInit() {
  }

}
